/** @jsx jsx */
import { Image, jsx } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";
import bannerImg from "../../static/imgs/help-donation.png";

const HelpUsa = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation HI, USA";
    return `<gatsby_donation donationId =  ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Donation For - HI, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_Donation_HI,_USA", event),
    []
  );

  return (
    <>
      <LayoutHome seoTitle="Heartfulness Program for Schools(HPS) -USA – Donations">
        <div
          sx={{
            padding: "40px 0px",
            backgroundColor: "#f7f7f7",
            marginBottom: "30px",
          }}
        >
          <div className="container">
            <h1
              className="text-center"
              sx={{ fontSize: "5.6em", fontWeight: "600" }}
            >
              <span sx={{ color: "#1da1f2" }}>H</span>
              <span sx={{ color: "#fe9717" }}>P</span>
              <span sx={{ color: "#a1df22" }}>S</span>
            </h1>
            <h3
              className="text-center"
              sx={{ fontSize: "1.6em", fontWeight: "600" }}
            >
              <span sx={{ color: "#1da1f2" }}>H</span>eartfulness –{" "}
              <span sx={{ color: "#fe9717" }}>P</span>rogram{" "}
              <span sx={{ color: "#a1df22" }}>F</span>or{" "}
              <span sx={{ color: "#f67766" }}>S</span>chools
            </h3>
          </div>
        </div>
        <div className="container">
          <div sx={{ position: "relative", paddingTop: "56.25%" }}>
            <iframe
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
              }}
              src="https://www.youtube.com/embed/ro3Ji5pdHpk?controls=0"
              name="fitvid0"
              frameBorder="0"
              allowFullScreen="allowfullscreen"
              title="Help USA"
            />
          </div>
        </div>
        &nbsp;
        <div className="container">
          <div style={{ backgroundColor: "#CDE8FE" }}>
            <h3 className="text-center" sx={{ fontSize: "1.6em" }}>
              Inviting Donors/ Sponsors/ Funding-Partners
            </h3>
          </div>

          <p
            className="text-center"
            sx={{ fontSize: "19px", marginTop: "20px", textAlign: "justify" }}
          >
            To join hands with Heartfulness towards imparting `life-skills
            programs` for one million college students across India
          </p>
          <p className="text-center">
            <Image src={bannerImg} />
          </p>
          <p
            className="text-center"
            sx={{ fontSize: "18px", textAlign: "justify" }}
          >
            For more details reach out to: (
            <a href="mailto:Education@heartfulness.org">
              Education@heartfulness.org
            </a>
            )
          </p>
        </div>
        &nbsp;
        <div
          className="container"
          sx={{ fontSize: "18px", textAlign: "justify" }}
        >
          <b sx={{ fontSize: "18px", color: "#333" }}>DONATE NOW:</b>
          <b>HPS:</b> Training on Essential Life-Skills completed for around
          300,000 students all over India in 2018 and moving towards the target
          of over one million students in 2019 with additional focus on
          remodelling & digitisation of school classrooms and curriculum
          impartment. Sponsoring cost is $1 per student only. You may choose to
          Sponsor students in multiples of 250 or any number of them as per your
          choice
        </div>
        <div className="container">
          <div className="tree_package" sx={{ marginTop: "15px" }}>
            <PageContent
              eventCallback={eventCallback}
              pageContent={pageContent(17, "Donate Now")}
            />
          </div>
        </div>
        <div
          sx={{
            textAlign: "center",
            margin: "auto",
            padding: "25px 15px",
            boxShadow: "0 2px 4px 0 rgba(170,170,170,0.5)",
            border: "solid 1px rgba(194,198,206,0.5)",
          }}
        >
          <p style={{ textAlign: "justify" }}>
            {" "}
            For matching double donation from your organisation, please send
            email request to
          </p>
          <p style={{ textAlign: "justify" }}>
            <a
              sx={{ wordBreak: "break-word", marginTop: "12px" }}
              href="mailto:us.treasurer@heartfulnessinstitute.org"
              target="_top"
              rel="noopener noreferrer"
            >
              us.treasurer@heartfulnessinstitute.org
            </a>
          </p>
        </div>
      </LayoutHome>
    </>
  );
};

export default HelpUsa;
